/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import historico from '../../../services/Historico';
import { Row, Col } from 'react-bootstrap';
import sortFunc from '../../../utils/ordernarColunaInt';
import moment from 'moment-timezone';
import sortFuncDate from '../../../utils/ordernarColunaDate';
import ModalEditar from './ModalEditar/index';
import RemoteTable from '../RemoteTable/index';
import { useSelector } from 'react-redux';
import ToggleLiberarResgate from './ToggleLiberarResgate';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalEditarContatos from './ModalEditarContatos/index';

export default function Revendas() {
  const usuario = useSelector((state) => {
    return state.usuario;
  });
  const [redesSelect, setRedesSelect] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState('');
  const [filtros, setFiltros] = useState(getInitialDatatableFiltro());
  const [history, setHistory] = useState('');
  const [gestoresSelect, setGestoresSelect] = useState([]); 
  const [gestorSelecionado, setGestorSelecionado] = useState('');

  function getInitialDatatableFiltro() {
    const params = {
      completo: 1,
      rede: redeSelecionada,
    };

    if (usuario.permissao !== 2) {
      params.ativo = 1;
    }
    return params;
  }

  const columns = [
    {
      dataField: 'codigo',
      text: 'Código',
      sort: true,
    },
    {
      dataField: 'nomeFantasia',
      text: 'Nome Fantasia',
      sort: true,
      formatter: (celula, valor) => {
        return (
          <button
            className="btn-limpo"
            onClick={(e) => {
              e.preventDefault();
              historico.push(`revenda/${valor.cnpj}`);
            }}
          >
            <b>{valor.nomeFantasia}</b>
          </button>
        );
      },
    },
    {
      dataField: 'nomeRede',
      text: 'Rede',
      sort: true,
      formatter: (celula, valor) => {
        return valor.nomeRede;
      },
    },
    {
      dataField: 'gestor',
      text: 'Gestor',
      sort: true,
      formatter: (celula, valor) => {
        return valor.gestor ? valor.gestor : '-';
      }
    },
    {
      dataField: 'razaoSocial',
      text: 'Razão Social',
      sort: true,
    },
    {
      dataField: 'cnpj',
      text: 'CNPJ',
      formatter: (celula, valor) => {
        let cnpj = valor.cnpj.replace(/[^\d]/g, '');
        return cnpj.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5'
        );
      },
      sort: true,
    },
    {
      dataField: 'telefone',
      text: 'Telefone',
      sort: true,
      formatter: (celula, valor) => {
        if (!valor.telefone) return '-';
        return `(${valor.telefone.substring(0, 2)}) ${valor.telefone.substring(
          2,
          6
        )}-${valor.telefone.substring(6)}`;
      },
    },
    {
      dataField: 'celular',
      text: 'Celular',
      sort: true,
      formatter: (celula, valor) => {
        if (!valor.celular) return '-';
        return `(${valor.celular.substring(0, 2)}) ${valor.celular.substring(
          2,
          7
        )}-${valor.celular.substring(7)}`;
      },
    },
    {
      dataField: 'endCidade',
      text: 'Cidade',
      sort: true,
    },
    {
      dataField: 'criadoEm',
      text: 'Data de Cadastro',
      formatter: (celula, valor) => {
        return valor.criadoEm && valor.criadoEm !== 0
          ? moment(valor.criadoEm).format('DD/MM/YYYY HH:mm:ss')
          : '';
      },
      sort: true,
    },
    {
      dataField: 'numeroParceiros',
      text: 'Nº Vendedores Lojas',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'totalVendas',
      text: 'Total Vendas',
      formatter: (celula, valor) => {
        return Number(valor.totalVendas)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'totalPontos',
      text: 'Pontuação Total',
      formatter: (celula, valor) => {
        return Number(valor.totalPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'saldoPontos',
      text: 'Saldo de Pontos',
      formatter: (celula, valor) => {
        return Number(valor.saldoPontos).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'saldoPontosAF',
      text: 'Saldo de Pontos AF',
      formatter: (celula, valor) => {
        return Number(valor.saldoPontosAF).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'resultado',
      text: 'Resultado AF',
      formatter: (celula, valor) => {
        return Number(
          Number(valor.saldoPontos) + Number(valor.saldoPontosAF)
        ).toLocaleString('pt-BR');
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'ultimaPontuacao',
      text: 'Última Pontuação',
      formatter: (celula, valor) => {
        return valor.ultimaPontuacao && valor.ultimaPontuacao !== 0
          ? moment(valor.ultimaPontuacao).format('DD/MM/YYYY HH:mm:ss')
          : '-';
      },
      sort: true,
      sortFunc: sortFuncDate,
    },
    {
      dataField: 'temRelatorio',
      text: 'Forma de Pontuação',
      formatter: (celula, valor) => {
        if (valor.lancamentoRelatorio !== 0 && valor.lancamentoManual !== 0)
          return 'Relatório e Manual';
        if (valor.lancamentoRelatorio !== 0 && valor.lancamentoManual === 0)
          return 'Relatório';
        if (valor.lancamentoRelatorio === 0 && valor.lancamentoManual !== 0)
          return 'Manual';
        return 'Manual';
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'tipoPontuacao',
      text: 'Tipo de Pontuação',
      formatter: (celula, valor) => {
        if (valor.tipoPontuacao === 1) {
          return 'Digitação';
        }

        if (valor.tipoPontuacao === 2) {
          return 'Foto';
        }
        return 'Sistema';
      },
      sort: true,
      sortFunc: sortFunc,
    },
    {
      isDummyField: true,
      dataField: 'ativo',
      text: 'Ativo',
      sort: true,
      hidden: [2].includes(usuario.permissao) ? false : true,
    },
    {
      isDummyField: true,
      dataField: 'resgateLiberado',
      text: 'Liberar Resgate',
      sort: true,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => {
        return (
          <ToggleLiberarResgate
            liberado={valor.resgateLiberado}
            revenda={valor.id}
          />
        );
      },
    },
    {
      dataField: 'editar',
      isDummyField: true,
      text: 'Editar',
      sort: false,
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
    },
    {
      dataField: 'editarContatos',
      isDummyField: true,
      text: 'Editar Contatos',
      formatter: (celula, valor) => (
        <button
          className="btn-limpo f-12 p-1"
          onClick={() => handleEditarContatos(valor.id)}
        >
          <i className="fas fa-edit"></i>
        </button>
      ),
      sort: false,
      hidden: [1, 2].includes(usuario.permissao) ? false : true,
    },
    {
      dataField: 'apagar',
      isDummyField: true,
      text: 'Apagar',
      sort: false,
      hidden: [2].includes(usuario.permissao) ? false : true,
    },
    {
      isDummyField: true,
      dataField: 'depara',
      text: 'De / Para',
      sort: false,
      hidden: [2].includes(usuario.permissao) ? false : true,
      formatter: (celula, valor) => {
        return valor.depara ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : (
          <FontAwesomeIcon icon={faTimes} />
        );
      },
    },
  ];

  const [showModalContatos, setShowModalContatos] = useState(false);
  const [revendaIdSelecionada, setRevendaIdSelecionada] = useState(null);

  const handleEditarContatos = (revendaId) => {
    setRevendaIdSelecionada(revendaId);
    setShowModalContatos(true);
  };

  useEffect(() => {
    loadHistory();
    buscarRedes();
    buscarGestores();
  }, []);

  useEffect(() => {
    setFiltros({ ...filtros, rede: redeSelecionada, gestor: gestorSelecionado });
    console.log('Filtros atualizados:', { ...filtros, rede: redeSelecionada, gestor: gestorSelecionado }); 
  }, [redeSelecionada, gestorSelecionado]);

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      setRedesSelect(resultado.data.redes);
    }
  };

  const loadHistory = async () => {
    const resultado = await api.get('/api/historico/ultima-data');

    if (resultado) setHistory(resultado.data.history);
  };

  const buscarGestores = async () => {
    const resultado = await api.get('/api/gestores'); 
    if (resultado) {
      setGestoresSelect(resultado.data); 
    }
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Lojas</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="gestorSelecionado">Selecione um Gestor</label>
          <select
            className="input-select mb-3"
            name="gestorSelecionado"
            id="gestorSelecionado"
            onChange={(e) => {
              setGestorSelecionado(e.target.value);
              console.log('Gestor selecionado:', e.target.value); 
            }}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todos</option>
            {gestoresSelect.map((gestor) => (
              <option value={gestor.nome} key={gestor.nome}>
                {gestor.nome}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      {history && (
        <Row>
          <Col>
            <p>
              Última atualização do Histórico:{' '}
              <b>
                {new Date(history?.criadoEm).toLocaleString('pt-BR', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })}
              </b>
            </p>
          </Col>
        </Row>
      )}
      <div>
        <RemoteTable
          dataId="id"
          url={`/api/revendas`}
          filtros={filtros}
          colunas={columns}
          ordenacaoInicial={{ campo: 'nomeFantasia', direcao: 'asc' }}
          fieldModalExlcluir="nomeFantasia"
          modalEdit={ModalEditar}
        />
      </div>
      <ModalEditarContatos
        show={showModalContatos}
        onHide={() => setShowModalContatos(false)}
        revendaId={revendaIdSelecionada}
      />
    </Container>
  );
}
